import {IntlIdsMap, IntlMessages} from "./_base";

export const RO_MESSAGES: IntlMessages = {
    ...IntlIdsMap,

    'search.placeholder': 'Cauta medicament',

    "map.marker.tooltip.title": 'Nu esti in zona?',
    "map.marker.tooltip.action.guess": "Citeste-mi locatia",
    "map.marker.tooltip.hint.move-marker": "sau trage de marker",
    'map.settings.popover.title': 'Setari',
    'map.settings.popover.search-distance': 'Distanta de cautare: {d} km',
    "results.settled.not-found": "Medicament negasit",
    "results.settled.stock.check": "Verifica stocuri",
    "results.settled.stock.checking": "Stocurile se verifica",
    "result.item.image.unavailable": "Imagine indisponibila",
    "result.item.price.unknown": "Pret indisponibil",
    "result.item.stock.unknown": "Suna in farmacie pentru a afla stocul",
    "result.item.stock.ok": "Stoc disponibil",

    "store.hours": `\
Lu: {0}
Ma: {1}
Mi: {2}
Jo: {3}
Vi: {4}
Sa: {5}
Du: {6}\
`,

    "store.tel": 'Suna: {tel}',

    "store.address.maps.open": "Vezi pe harta",

    "pharmacy.no-stock": 'Nu set pot verifica stocurile'
}