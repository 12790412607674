import {observer} from "mobx-react-lite";
import {Product} from "../../api/types";
import React from "react";
import {getPharmacyAssets} from "../../stores/stores";
// import {AppState} from "../../stores/app";
// import {Spinner} from 'react-bootstrap'

export const ProductComponent = observer(({x}: { x: Product }) => {
    // const [stock] = useState(new PharmacyStock(x.pharmacy, x.id))

    // const settled = AppState.stock.settled || !!AppState.stock.inventoryList.find(iitem => iitem.product.id === x.id && iitem.product.pharmacy === x.pharmacy)

    const assets = getPharmacyAssets(x.pharmacy)

    return (
        <div key={x.id} style={{ margin: 8}}>
            {/*{!settled && (*/}
            {/*    <Spinner animation="border" size="sm"/>*/}
            {/*)}*/}

            {assets.name} - {x.name}

            {/*{!stock.data &&*/}
            {/*    <button onClick={() => stock.populate()}>check stock</button>}*/}
            {/*{stock.fetching && <div>loading stock</div>}*/}
            {/*{stock.data && (*/}
            {/*    <div>*/}
            {/*        {stock.data.locations.map(l => {*/}
            {/*            const pharm = Stores.map.get(l.pharmacy)?.get(l.id)*/}
            {/*            // if(!pharm) return <div>{l.pharmacy} {l.id} </div>;*/}
            {/*            if (!pharm) return null;*/}

            {/*            return <div>{l.maybe && '?????'} {pharm.address} </div>*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    )
})
