import {action, computed, makeObservable, observable} from "mobx";
import {PharmacyStock} from "./search";
import {Product, ProductStock, Store} from "../api/types";
import {getPharmacyAssets, Stores} from "./stores";

export class StockStoreClass {

    @observable
    inventoryList: { product: Product, stock: PharmacyStock }[] = []

    constructor() {
        makeObservable(this)
    }

    @action
    checkStocks(items: Product[]) {
        if (!this.settled) return;

        this.inventoryList = items.map(x => ({
            product: x,
            stock: new PharmacyStock(x.pharmacy, x.id)
        }))
    }

    @computed
    get settled() {
        return this.inventoryList.every(x => !x.stock.fetching)
    }

    @computed
    get inventoryDataList() {
        if (!this.settled) return [];

        return this.inventoryList.filter(x => x.stock.data).map(x => ({
            product: x.product,
            stock: x.stock.data!
        }));
    }

    @computed
    get stores() {
        const list = this.inventoryDataList;

        const pharmacies = new Map<string, {
            pharmacy: Store,
            items: {maybe?: boolean, item: Product}[]
        }>()



        for (const item of list) {
            for (const location of item.stock.locations) {

                const store = Stores.map.get(location.pharmacy)?.get(location.id);
                if(!store || !store.lat ||!store.long) continue;

                // const assets = getPharmacyAssets(item.product.pharmacy);
                const pid = location.pharmacy + '-' + location.id;

                if(!pharmacies.has(pid)){
                    pharmacies.set(pid, {
                        pharmacy: store,
                        items: []
                    });
                }
                pharmacies.get(pid)!.items.push({maybe: location.maybe, item: item.product})

            }
        }

        return [...pharmacies.values()]
    }
}