export class TimeoutError extends Error{
    name = 'TimeoutError'
}
export async function timeout<T>(p: Promise<T>, ms: number): Promise<T> {

    return Promise.race([
        p,
        new Promise<T>((res, rej) => setTimeout(() => rej(new TimeoutError()), ms))
    ]);

}