import React, {HTMLProps, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {getPharmacyAssets, Stores} from "../../stores/stores";
import {Circle, MapContainer, Marker, Popup, TileLayer, useMap,} from "react-leaflet";
import {
    Circle as LeafleftCircle,
    Icon as LeafletIcon,
    Icon,
    LeafletEventHandlerFnMap,
    LeafletMouseEvent,
    Map as LeafletMap,
    Marker as LeafletMarker,
    Popup as LeafletPopup
} from "leaflet";
import image from "leaflet/dist/images/marker-icon.png"
import image2x from "leaflet/dist/images/marker-icon-2x.png"
import {Button, Form, OverlayTrigger, Popover} from "react-bootstrap";
import {GearFill} from "react-bootstrap-icons";
import {action} from "mobx";
import "leaflet/dist/leaflet.css"
import css from './map.module.scss'
import {IntlMsg} from "../../lang/components";
import {AppState} from "../../stores/app";
import classNames from "classnames";
import {Pharmacies} from "../../api/pharmacies";


type MapProps = {
    style?: React.CSSProperties,
    className?: string

    onClick?: (e: LeafletMouseEvent) => void
}

const icon = new LeafletIcon({
    iconUrl: image,
    iconRetinaUrl: image2x,
    iconSize: [44, 44 * 1.6],
    iconAnchor: [44 / 2, 44 * 1.6 - 4]
})

const  HeroMarker = observer(()=> {
    const [draggable, setDraggable] = useState(true);
    const markerRef = useRef<LeafletMarker>(null);
    const popupRef = useRef<LeafletPopup>(null);
    const circle = useRef<LeafleftCircle>(null);
    const map = useMap();

    //@ts-ignore
    window.map = map;

    const eventHandlers: LeafletEventHandlerFnMap = useMemo(
        () => ({
            dragend: action(() => {
                const marker = markerRef.current
                if (marker != null) {
                    const latlong = marker.getLatLng()
                    Stores.setLocation(latlong.lat, latlong.lng);
                    Stores.setAccuracy(0)

                    // ugly fix for map pan interrupted by popup opening
                    setTimeout(() => {
                        map.panTo(latlong)
                    }, 10)
                }
            }),
            // fixme maybe make the ability to tap/click on the map too?
            // click(e: LeafletMouseEvent) {
            //     console.log(e)
            //     if (draggable) {
            //         Stores.setLocation(e.latlng.lat, e.latlng.lng)
            //     }
            // },
        }),
        // [draggable],
        [],
    )
    const toggleDraggable = useCallback((e: React.MouseEvent) => {
        setDraggable(!draggable);

        // can't update the color the react way, so we update by ref
        circle.current?.setStyle({
            fillColor: !draggable ? 'green' : 'blue'
        });

        e.preventDefault();
        e.stopPropagation();
    }, [draggable]);

    // useEffect(() => {
    //     if (map && popupRef.current && !popupRef.current.isOpen()) {
    //         // @ts-ignore works with the popup instance too
    //         map.openPopup(popupRef.current, Stores.myLocationLatLng, {})
    //     }
    // }, [map, popupRef, Stores.myLocationLatLng]);

    useEffect(() => {
        const to = setTimeout(() => {
            if (map && popupRef.current && !popupRef.current.isOpen()) {
                // @ts-ignore works with the popup instance too
                map.openPopup(popupRef.current, Stores.myLocationLatLng, {})
            }
        }, 1500)

        return () => {
            clearTimeout(to);
        }
    }, [map, popupRef]);

    return (
        <>
            {/*fixme circle doesn't change the fillColor so we re-render it, .redraw() doesn't work*/}
            <Circle
                center={Stores.myLocationLatLng}
                // @ts-ignore
                stroke={false}
                fillColor={'blue'}
                fill
                fillOpacity={0.15}
                radius={Stores.distanceKM * 1000}
                interactive={false}
                ref={circle}
            />

            <Marker
                icon={icon}
                draggable={draggable}
                eventHandlers={eventHandlers}
                position={Stores.myLocationLatLng}
                ref={markerRef}
            >
                <Popup
                    minWidth={90}
                    interactive
                    ref={popupRef}
                    autoClose={false}
                    offset={[0, -44]}
                >
                    <div className={css.heroMarkerTooltip}>

                        <h4>
                            <IntlMsg id="map.marker.tooltip.title"/>
                        </h4>

                        <Button
                            onClick={() => Stores.updateLocationViaBrowser().then(() => {
                                if (popupRef.current) {
                                    map.closePopup(popupRef.current)
                                }
                            })}
                        >
                            <IntlMsg id="map.marker.tooltip.action.guess"/>
                        </Button>

                        <h6>
                            <IntlMsg id="map.marker.tooltip.hint.move-marker"/>
                        </h6>

                    </div>
                </Popup>
            </Marker>
        </>
    );
})

const MapSettings = observer(() => {

    const popup = (
        <Popover>
            <Popover.Header as="h3"><IntlMsg id="map.settings.popover.title"/></Popover.Header>
            <Popover.Body className={css.settingsBody}>
                <Form.Label
                    style={{marginBottom: 0}}
                >
                    <IntlMsg id="map.settings.popover.search-distance" values={{
                        d: Stores.distanceKM.toFixed(1)
                    }}/>
                </Form.Label>
                <br/>
                <Form.Range
                    min={0.5}
                    max={15}
                    step={0.5}
                    value={Stores.distanceKM}
                    onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        Stores.setDistance(parseFloat(e.target.value))
                    }}
                />

                <hr/>

                {/*fixme known issues: changing checkbox doesn't change map markers*/}
                <div className={css.pharmacyList}>
                    {Pharmacies.data?.map(p => (
                        <Form.Check type="checkbox" key={p}>
                            <Form.Check.Input
                                type="checkbox"
                                checked={AppState.search.enabledPharmacies.get(p)}
                                onChange={x => AppState.search.enabledPharmacies.set(p, x.target.checked)}
                            />
                            <Form.Check.Label>{getPharmacyAssets(p).name} {!getPharmacyAssets(p).canCheckStock &&
                                <span>(<IntlMsg id="pharmacy.no-stock"/>)</span>}</Form.Check.Label>
                        </Form.Check>
                    ))}
                </div>

            </Popover.Body>
        </Popover>
    )

    return (
        <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose
            overlay={popup}
        >
            <div className={css.settings}>
                <GearFill/>
            </div>
        </OverlayTrigger>
    )

})

export const AppMap = observer((props: MapProps & HTMLProps<HTMLDivElement>) => {
    const map = useRef<LeafletMap>(null);

    useEffect(() => {
        map.current?.setView(Stores.myLocationLatLng)
    }, [map, Stores.myLocation])

    // fix for map not auto adjusting to the new size
    useEffect(() => {
        setTimeout(() => {
            map.current?.invalidateSize({
                animate: false,
                debounceMoveend: false
            })
        }, 200)
    }, [map, AppState.searchOpened])

    if (!Stores.data) return <div style={props.style} className={props.className}></div>;

    const {className, ...otherProps} = props;

    return (
        <div className={classNames(css.container, className)} {...otherProps}>

            <MapSettings/>

            <MapContainer
                // @ts-ignore
                center={Stores.myLocationLatLng}
                zoom={12}
                scrollWheelZoom
                className={css.map}
                ref={map}
                zoomControl
                attributionControl
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    // http://leaflet-extras.github.io/leaflet-providers/preview/index.html
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    // detectRetina={true}
                    tileSize={512}
                    zoomOffset={-1}
                />

                <HeroMarker/>

                {
                    AppState.stock.stores.map(({pharmacy, items}) => {

                        const assets = getPharmacyAssets(pharmacy.pharmacy);

                        // const mapsSearch = encodeURIComponent(
                        //     pharmacy.pharmacy + ' ' +
                        //     (pharmacy.address ?? '') + ', ' +
                        //     (pharmacy.lat && pharmacy.long ? `${pharmacy.lat},${pharmacy.long}` : '')
                        // );
                        const mapsSearch = encodeURIComponent(
                            pharmacy.pharmacy + ' ' +
                            (pharmacy.address ?? '')// + ', ' +
                            // (pharmacy.lat && pharmacy.long ? `${pharmacy.lat},${pharmacy.long}` : '')
                        );

                        // todo make this as separate component item with memo?
                        // todo implement https://www.npmjs.com/package/react-leaflet-cluster per pharmacy
                        return (
                            <Marker
                                key={`${pharmacy.pharmacy}-${pharmacy.id}`}
                                draggable={false}
                                position={[pharmacy.lat, pharmacy.long]}
                                interactive
                                icon={new Icon({
                                    iconSize: [36, 36],
                                    iconUrl: assets.icon,
                                    className: classNames(css.pharmacy, items.every(x => x.maybe) && css.maybe)
                                })}
                            >
                                <Popup
                                    minWidth={90}
                                    interactive={false}
                                    autoClose={false}
                                    offset={[0, 0]}
                                    className={css.pharmacyPopup}
                                >
                                    <div className={css.pharmacyTooltip}>
                                        <div className={css.pharmacyStore}>
                                            <h5 className={css.name}>
                                                {assets.name}
                                            </h5>
                                            <h6 className={css.address}>
                                                {pharmacy.address}
                                            </h6>
                                            <Button
                                                size="sm"
                                                variant="outline-primary"
                                                className={css.mapsLink}
                                                href={`https://www.google.com/maps/search/${mapsSearch}/@${pharmacy.lat},${pharmacy.long},15z`}
                                                target="_blank"
                                            >
                                                <IntlMsg id="store.address.maps.open"/>
                                            </Button>
                                            {pharmacy.phone &&
                                                <Button
                                                    size="sm"
                                                    variant="outline-primary"
                                                    className={css.phone}
                                                    href={`tel:${pharmacy.phone}`}
                                                    target="_blank"
                                                >
                                                    <IntlMsg id="store.tel" values={{tel: pharmacy.phone}}/>
                                                </Button>
                                            }

                                            {/*{pharmacy.hours &&*/}
                                            {/*    <pre className={css.hours}>*/}
                                            {/*        <IntlMsg*/}
                                            {/*            id="store.hours"*/}
                                            {/*            values={Object.assign({}, pharmacy.hours.split('\n')) as unknown as Record<string, string>}*/}
                                            {/*        />*/}
                                            {/*    </pre>*/}
                                            {/*}*/}
                                        </div>
                                        <hr/>

                                        {
                                            items.map(x => {
                                                return (
                                                    <div className={css.item} key={x.item.id}>
                                                        {x.item.image ? (
                                                            <img
                                                                className={css.image}
                                                                src={x.item.image ?? ''}
                                                            />
                                                        ) : (
                                                            <div
                                                                className={classNames(css.image, css.noImage)}
                                                            >
                                                                <IntlMsg id="result.item.image.unavailable"/>
                                                            </div>
                                                        )}


                                                        <div className={css.text}>
                                                            <h6>{x.item.name}</h6>
                                                            {x.item.price ?
                                                                `${x.item.price} RON` :
                                                                <IntlMsg id="result.item.price.unknown"/>
                                                            }
                                                            {x.maybe ? (
                                                                <div className={css.maybe}>
                                                                    <IntlMsg id="result.item.stock.unknown"/>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <IntlMsg id="result.item.stock.ok"/>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Popup>
                            </Marker>
                        )
                    })
                }
            </MapContainer>
        </div>
    )

})