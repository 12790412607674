import {action, computed, makeObservable, observable, reaction} from "mobx";
import {SearchStore,} from "./search";
import {StockStoreClass} from "./stock";

export class AppStateClass {

    @observable
    searchOpened = false

    search = new SearchStore()
    stock = new StockStoreClass()

    constructor() {
        makeObservable(this)
        // reaction(() => this.search.products, d => console.log(d))
        // reaction(() => this.search.status, d => console.log(d))

        // close the search when stock settles and we have inventory
        reaction(() => this.stock.settled, action(settled => {
            if (settled && this.stock.inventoryList.length) {
                this.searchOpened = false
            }
        }))
    }

    @computed
    get stockAndStores() {
        return this.stock.inventoryDataList
    }

    @computed
    get loading() {
        return !this.search.settled || !this.stock.settled
    }

    @action openResults = () => {
        this.searchOpened = true;
    }
    @action closeResults = () => {
        this.searchOpened = false;
    }
    @action toggleResults = () => {
        this.searchOpened = !this.searchOpened;
    }
}

export const AppState = new AppStateClass()