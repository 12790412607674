export const intlMessageIds = [

    'search.placeholder',

    'map.marker.draggable.title',
    'map.marker.tooltip.title',
    'map.marker.tooltip.action.guess',
    'map.marker.tooltip.hint.move-marker',
    // 'map.marker.draggable.action.place',

    // 'map.marker.non-draggable.title',
    // 'map.marker.non-draggable.action.move',
    'map.marker.non-draggable.action.guess',
    'map.settings.popover.title',
    'map.settings.popover.search-distance',

    'results.settled.not-found',
    'results.settled.stock.check',
    'results.settled.stock.checking',

    'result.item.image.unavailable',
    'result.item.price.unknown',
    'result.item.stock.unknown',
    'result.item.stock.ok',

    'store.address.maps.open',
    'store.hours',
    'store.tel',


    'pharmacy.no-stock'
] as const;

export type IntlMessageIdTypes = typeof intlMessageIds[number]

export type IntlMessages = { [s in IntlMessageIdTypes]: string }

export const IntlIdsMap: IntlMessages = intlMessageIds.reduce((a, c) => {
    a[c] = c;
    return a
}, {} as IntlMessages)