import {observer} from "mobx-react-lite";
import css from "./results.module.scss";
import {AppState} from "../../stores/app";
import React, {HTMLProps, useCallback} from "react";
import {ProductComponent} from "./product";
import classNames from "classnames";
import {IntlMsg} from "../../lang/components";
import {Accordion, Button, Spinner} from "react-bootstrap";
import {Product} from "../../api/types";

export const Results = observer((props: HTMLProps<HTMLDivElement>) => {
    const {className, ...other} = props;

    return (
        <div className={classNames(css.resultsContainer, className)} {...other}>
            <div className={css.results}>

                {AppState.search.products.length === 0 && !AppState.search.emptySearch && (
                    <h2 className={css.notFound}>
                        <IntlMsg id="results.settled.not-found"/>
                    </h2>
                )}

                <Accordion className={css.list}>
                    {
                        AppState.search.products.map(([token, list]) => {
                            return (
                                <ProductList key={token} list={list} token={token}/>
                            )
                        })
                    }
                </Accordion>
            </div>
        </div>
    )
})

const ProductList = observer(({token, list}: { token: string, list: typeof AppState.search.products[number][1] }) => {

    const checkStock = useCallback(() => {
        AppState.stock.checkStocks(list)
    }, [list])

    return (
        <Accordion.Item eventKey={token} key={token} className={css.item}>
            <Accordion.Header className={css.header}>
                <span className={css.content}>
                    <span className={css.displayName}>{list[0].displayName}</span>

                    <span className={css.realName}>{list[0].name}</span>
                </span>
            </Accordion.Header>

            <Accordion.Body className={css.body}>
                <Button
                    onClick={checkStock}
                    disabled={AppState.loading}
                >
                    {!AppState.stock.settled &&
                        <Spinner animation="border" size="sm"/>
                    }
                    <IntlMsg
                        id={AppState.stock.settled ?
                            "results.settled.stock.check" :
                            "results.settled.stock.checking"
                        }
                    />
                </Button>
                {
                    list.map(x => <ProductComponent x={x} key={x.id}/>)
                }
            </Accordion.Body>
        </Accordion.Item>
    )

})