import {DataFrame, DataFrameOptions} from "../helpers/dataframe";
import {API, processApiResp, Store} from "../api/types";
import {action, computed, makeObservable, observable} from "mobx";
import {calcCrow} from "../helpers/math";

import donaImg from '../imgs/pharmacies/dona.png'
import belladonnaImg from '../imgs/pharmacies/belladonna.jpg'
import catenaImg from '../imgs/pharmacies/catena.png'
import drMaxImg from '../imgs/pharmacies/drmax.png'
import helpnetImg from '../imgs/pharmacies/helpnet.png'
import defaultImg from '../imgs/pharmacies/__default__.jpg'

type StoreResponse = {
    me: {
        lat: number
        long: number
    },
    stores: Store[]
}

export class StoresAndLocationClass extends DataFrame<StoreResponse> {
    keepPreviousDataWhiteFetching = true

    constructor(opts: DataFrameOptions = {}) {
        super(opts);
        makeObservable(this)
    }

    protected fetch(): Promise<StoreResponse> {
        return processApiResp(fetch(`${API}/stores`))
    }

    @observable
    customLocation: null | { lat: number, long: number } = null;

    @observable
    accuracy: number = 10_000

    @computed
    get myLocation() {
        return this.customLocation ?? this.data?.me ?? {lat: 0, long: 0};
    }

    @computed
    get myLocationLatLng(): [number, number] {
        const {lat, long} = this.myLocation;
        return [lat, long];
    }

    @action
    setLocation(lat: number, long: number) {
        this.customLocation = {lat, long};
    }

    @action
    setAccuracy(a: number) {
        this.accuracy = a;
    }

    @observable
    distanceKM = 5;

    @action
    setDistance(d: number) {
        this.distanceKM = d;
    }

    @computed
    get map() {

        const map = new Map<string, Map<string, Store>>()
        const mePos = this.myLocation!;

        const stores = this.data?.stores ?? [];

        for (let i = 0; i < stores.length; i++) {
            const store = stores[i];

            if (this.distanceKM > 0) {
                if (!store.long || !store.lat) {
                    // console.warn('no lat/long for', store)
                    continue;
                }

                const dist = calcCrow(mePos.lat, mePos.long, store.lat, store.long);
                if (this.distanceKM < dist) {
                    // console.warn('store too far away', dist, store)
                    continue;
                }
            }

            const pharmMap = map.has(store.pharmacy) ? map.get(store.pharmacy)! : map.set(store.pharmacy, new Map()).get(store.pharmacy)!;

            pharmMap.set(store.id, store)
        }

        return map;
    }

    updateLocationViaBrowser = async () => {
        try {
            const position = await new Promise<GeolocationPosition>((res, rej) => navigator.geolocation.getCurrentPosition(res, rej));

            this.setLocation(position.coords.latitude, position.coords.longitude)
            this.setAccuracy(position.coords.accuracy)
        } catch (e: any) {
            // fixme no alerts pls
            const positionError = e as GeolocationPositionError;

            alert(positionError.message)
            console.error(positionError)
        }
    }

}

export const Stores = new StoresAndLocationClass({autoFetch: true})

// @ts-ignore
window.Stores = Stores;

//todo move this to backend
const storeAssets = {
    'dona': {
        icon: donaImg,
        name: 'Dona',
        canCheckStock: true,
    },
    'belladonna': {
        icon: belladonnaImg,
        name: 'Belladonna',
        canCheckStock: true,
    },
    'catena': {
        icon: catenaImg,
        name: 'Catena',
        canCheckStock: true,
    },
    'DrMax': {
        icon: drMaxImg,
        name: 'DrMax',
        canCheckStock: true,
    },
    'helpnet': {
        icon: helpnetImg,
        name: 'Help Net',
        canCheckStock: false,
    },
    '__DEFAULT__': {
        icon: defaultImg,
        // fixme can't INTL this
        name: 'Farmacie',
        canCheckStock: false
    }
} satisfies { [s: string]: { icon: string, name: string, canCheckStock: boolean } }

export function getPharmacyAssets(pharma: string) {
    return storeAssets[pharma as keyof typeof storeAssets] ?? storeAssets.__DEFAULT__;
}