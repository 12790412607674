import {observer} from "mobx-react-lite";
import {AppState} from "../../stores/app";
import {Spinner} from "react-bootstrap";
import {CheckCircleFill, XCircleFill} from "react-bootstrap-icons";
import React from "react";
import css from './searching.module.scss'
import classNames from "classnames";

export const Searching = observer(() => {
    return (
        <div className={css.container}>
            {
                AppState.search.statusList.map(([pharmacy, status]) => (
                    <h3
                        key={pharmacy}
                        className={classNames(css.item, status!=='loading' && css.static)}
                        data-text={pharmacy}
                    >
                        {pharmacy}

                        {/*<div className={css.status}>*/}
                        {/*    {status === 'loading' && <Spinner animation="border"/>}*/}
                        {/*    {status === 'done' && <CheckCircleFill color="green"/>}*/}
                        {/*    {status === 'error' && <XCircleFill color="red"/>}*/}
                        {/*</div>*/}
                    </h3>
                ))
            }
        </div>
    );
});