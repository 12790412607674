import {action, computed, makeObservable, observable} from "mobx";
import {API, processApiResp, Product, ProductStock} from "../api/types";
import {timeout} from "../helpers/async";
import slug from "slug";
import {DataFrame} from "../helpers/dataframe";
import {Pharmacies} from "../api/pharmacies";
import levenshtein from 'fast-levenshtein'
import stringSimilarity from "string-similarity-js";
import {group, matchSetPerc} from "../helpers/match";
import {getPharmacyAssets} from "./stores";


type SearchStatus = 'loading' | 'done' | 'error' | 'none'

export class PharmacySearch extends DataFrame<Product[]> {
    timeout = 10_000
    keepPreviousDataWhiteFetching = false

    protected text: string = ''
    readonly pharmacy: string

    constructor(pharmacy: string) {
        super({autoFetch: false});
        this.pharmacy = pharmacy;
    }


    protected async fetch(): Promise<Product[]> {
        return await processApiResp<Product[]>(timeout(fetch(`${API}/search/${this.pharmacy}?q=${encodeURIComponent(this.text)}`), this.timeout))
    }

    async search(text: string) {
        this.text = text.toLowerCase().trim();
        return this.get(true);
    }

}

export class SearchStore {

    @observable
    searches: PharmacySearch[] = []

    @observable
    enabledPharmacies = new Map<string, boolean>()

    @observable
    protected text: string = ''

    constructor() {
        Pharmacies.get().then(action(pharmacies => {
            pharmacies.forEach(p => {
                // todo maybe this can be @computed?
                this.searches.push(new PharmacySearch(p))

                const assets = getPharmacyAssets(p)
                this.enabledPharmacies.set(p, assets.canCheckStock)
            });

        }));
        makeObservable(this);
    }

    @action
    search(text: string) {
        this.text = text;
        this.searches.forEach(s => s.search(text))
    }

    @computed
    get emptySearch() {
        return !this.text.trim();
    }

    @computed
    get products() {

        const products: Product[] = this.searches
            .map(x => x.data)
            .filter(x => !!x)
            .filter(x => x![0] && this.enabledPharmacies.get(x![0].pharmacy))
            .flat() as Product[];

        // return this._mergeProductsV3(products);
        //
        return this._mergeProductsV4(products);
    }

    private _mergeProductsV3(products: Product[]) {
        const searchTokens = this.text.toLowerCase().split(' ')

        if (!products.length) return [];

        const tokenizedProduct = products.map(p => {

            // find token
            const name = p.name
                .trim()
                // remove - + . x
                .replace(/[-+.&;,]/ig, ' ')
                // match x 20 to x20
                .replace(/\s+x\s+(\d+)/ig, ' x$1')
                // fix multi-spaces
                .replace(/\s+/g, ' ')
                // concat numbers with units
                .replace(/(\d+)\s*(mg|ml|dg|dl|ca?p|co?m?p|pc|pic|pl|plic|plicuri)/ig, '$1$2')
                // remove spaces next to slashes
                .replace(/\s*\/\s*/g, '/')
                // replace , with . to better parse numbers later
                .replace(/(\d+),(\d+)/g, '$1.$2')

            const words = new Set(name.toLowerCase().split(' '))

            const numbersAndUnits: string[] = [];
            const regex = /\d+(mg|ml|dg|dl|ca?p|co?m?p|pc|pic|pl|plic|plicuri)/g;

            let regexResult: RegExpExecArray | null = null;
            while (regexResult = regex.exec(name)) {
                numbersAndUnits.push(regexResult[0]);
            }

            console.log(words, numbersAndUnits);

            return {
                product: p,
                words,
                numbers: new Set(numbersAndUnits)
            }

            // let firstNumberIndex = -1;
            // let firstSpaceAfterNumber = -1;
            // let i = 0;
            // for (; i < name.length; i++) {
            //     if ("0" <= name[i] && name[i] <= "9") {
            //         firstNumberIndex = i;
            //         break;
            //     }
            // }
            // for (; i < name.length; i++) {
            //     if (" " === name[i]) {
            //         firstSpaceAfterNumber = i;
            //         break;
            //     }
            // }
            //
            // const protoname = name.slice(0, firstNumberIndex).trim();
            // // parse the rest of a string as a float (trailing text is ignored)
            // const number = name.slice(firstNumberIndex, firstSpaceAfterNumber + 1).trim();
            //
            // return Object.assign({
            //     words: words,
            //     token: `${slug(protoname)}-${number}`.toLowerCase(),
            //     displayName: `${protoname} ${number}`.trim()
            // }, p)
        });

        /*
        todo
            start with first product
            for every group
                for every product
                    check how many tokens match
                        check token match with string similarity
                    get a percentage by numbers of tokens that match divided by min of cnt(words)
                    if perc > 80%
                        then it's a match, add to group, short-circuit
                        else new group, short-circuit
         */

        const groups: { words: Set<string>, product: Product, numbers: Set<string> }[][] = [];

        groups.push([tokenizedProduct[0]])

        for (let i = 1; i < tokenizedProduct.length; i++) {
            const item = tokenizedProduct[i];

            let groupFound = false;
            group_loop: for (const group of groups) {

                for (const groupItem of group) {




                    if (matchSetPerc(item.words, groupItem.words) > 0.9) {

                        // check for units mismatch
                        if(matchSetPerc(item.numbers, groupItem.numbers) === 1){
                            // console.log('M', [...item.words], '|', [...groupItem.words], 'match', matchSetPerc(item.words, groupItem.words), );
                            group.push(item);
                            groupFound = true;
                            break group_loop;
                        }else {
                            console.log('DX', [...item.words], '|', [...groupItem.words], 'match', matchSetPerc(item.numbers, groupItem.numbers) , );
                        }


                    }else{
                        console.log('D', [...item.words], '|', [...groupItem.words], 'match', matchSetPerc(item.words, groupItem.words),  );
                    }
                }

            }

            if (!groupFound) {
                groups.push([item]);
            }
        }

        console.log(groups)
        return groups.map(g => [
            g[0].product.name,
            g.map(x => ({...x.product, displayName: x.product.name}))
        ] as const)
    }

    private _mergeProductsV2(products: Product[]) {

        // worse performance than the greedy heuristic v1 :(
        // O(n^2 log(n) * m)

        const listOfLists: Product[][] = [];

        for (let i = 0; i < products.length; i++) {
            const product = products[i]
            let productAdded = false

            product_check:
                for (let j = 0; j < listOfLists.length; j++) {
                    const nowChecking = listOfLists[j];

                    product_group_check:
                        for (let k = 0; k < nowChecking.length; k++) {
                            const nowCheckingEntry = nowChecking[k];

                            const similarity = stringSimilarity(product.name.replace(/-/, ' '), nowCheckingEntry.name.replace(/-/, ' '));
                            if (similarity > .80) {
                                nowChecking.push(product);
                                productAdded = true
                                break product_check;
                            }

                            // optimization: if the similarity is very low, don't bother with the rest of the group
                            if (similarity < 0.5) {
                                break product_group_check;
                            }
                        }


                }

            if (!productAdded) {
                listOfLists.push([product])
            }
        }

        return listOfLists.map(list => ([
            slug(list[0].name),
            list.map(x => ({...x, displayName: x.name}))
        ]) as const);
    }

    private _mergeProductsV1(products: Product[]) {
        // O(n*m)

        const searchTokens = this.text.toLowerCase().split(' ')

        const tokenizedProduct = products.map(p => {

            // find token
            const name = p.name
                .trim()
                // remove - + . x
                .replace(/[-+.]/, ' ')
                .replace(/\s+x\s+/, ' ')
                // fix multi-spaces
                .replace(/\s+/g, ' ')
                // concat numbers with units
                .replace(/(\d+)\s*(mg|ml|dg|dl)/g, '$1$2')
                // remove spaces next to slashes
                .replace(/\s*\/\s*/g, '/')
                // replace , with . to better parse numbers later
                .replace(/(\d+),(\d+)/g, '$1.$2')

            const words = new Set(name.toLowerCase().split(' '))

            let firstNumberIndex = -1;
            let firstSpaceAfterNumber =-1;
            let i = 0;
            for (; i < name.length; i++) {
                if ("0" <= name[i] && name[i] <= "9") {
                    firstNumberIndex = i;
                    break;
                }
            }
            for (; i < name.length; i++) {
                if (" " === name[i]) {
                    firstSpaceAfterNumber = i;
                    break;
                }
            }

            const protoname = name.slice(0, firstNumberIndex).trim();
            // parse the rest of a string as a float (trailing text is ignored)
            const number = name.slice(firstNumberIndex, firstSpaceAfterNumber + 1).trim();

            return Object.assign({
                words: words,
                token: `${slug(protoname)}-${number}`.toLowerCase(),
                displayName: `${protoname} ${number}`.trim()
            }, p)
        })

        const groups = tokenizedProduct.reduce((a, c) => {

            if (!a.has(c.token)) a.set(c.token, [])

            a.get(c.token)!.push(c);

            return a;
        }, new Map<string, typeof tokenizedProduct>());

        [...groups.values()].forEach(x => {
            const image = x.find(i => i.image)?.image;

            // fix missing images
            x.forEach(i => {
                if(!i.image) {
                    i.image = image;
                }
            })
        })

        const groupList = [...groups.entries()];
        const tokenDistance = new Map(groupList.map(x => [
            x[0],
            // calc the amount of tokens found int he results
            // this is O(m*n) might be heavy
            searchTokens.length - searchTokens.filter(st => x[1].some(xt => xt.words.has(st))).length]
        ))
        const levDistance = new Map(groupList.map(x => [
            x[0],
            // levy distance
            levenshtein.get(this.text.toLowerCase(), x[0].toLowerCase())
        ]))

        return groupList.sort((a, b) => {
            // sort by tokens found
            const tokenDiff = tokenDistance.get(a[0])! - tokenDistance.get(b[0])!;
            if (tokenDiff) return tokenDiff;

            // sort by levanstain distance
            const distDiff = levDistance.get(a[0])! - levDistance.get(b[0])!;
            if (distDiff) return distDiff;

            // sort by count of results
            return b[1].length - a[1].length;
        });
    }

    private _mergeProductsV4(products: Product[]) {
        // O(n*m)

        const data = group(
            this.text,
            products,
            x => x.name.toLowerCase().trim()
                // replace , with . to better parse numbers later
                .replace(/(\d+),(\d+)/g, '$1.$2')
                // remove - + . x
                // .replace(/[-+,]/, ' ')
                .replace(/\s+x\s+/, ' ')
                // fix multi-spaces
                .replace(/\s+/g, ' ')
                // concat numbers with units
                .replace(/(\d+)\s*(mg|ml|dg|dl)/g, '$1$2')
                // remove spaces next to slashes
                .replace(/\s*\/\s*/g, '/')
        )

        console.log(data);

        // fixme optimize data structure, this is horrible
        return data.cluster.map(c =>
            [c[0].name, c.map(x => Object.assign({displayName: x.name}, x))] as const
        )

    }


    @computed
    get status() {
        const stat: {
            [s: string]: SearchStatus
        } = {}

        for (let i = 0; i < this.searches.length; i++) {
            const s = this.searches[i];

            stat[s.pharmacy] = (
                s.fetching ? 'loading' :
                    s.data ? 'done' :
                        s.error ? 'error' :
                            'none'
            )
        }
        return stat;
    }

    @computed
    get statusList(): [string, SearchStatus][] {
        const stat = this.status;

        return Object.keys(stat).map(p => [p, stat[p]])
    }

    @computed
    get settled() {
        return this.searches.every(s => !s.fetching)
    }
}


export class PharmacyStock extends DataFrame<ProductStock> {
    timeout = 15_000
    keepPreviousDataWhiteFetching = false

    readonly pharmacy: string
    readonly id: string

    constructor(pharmacy: string, id: string) {
        super({autoFetch: false});
        this.pharmacy = pharmacy;
        this.id = id;
        this.populate().catch(console.error)
    }

    protected async fetch(): Promise<ProductStock> {
        return await processApiResp<ProductStock>(timeout(fetch(`${API}/stock/${this.pharmacy}/${this.id}`), this.timeout))
    }

}