import React, {useEffect, useRef, useState} from 'react';
import './App.scss';

import css from './App.module.scss';

import {AppState, AppStateClass} from "./stores/app";
import {observer} from "mobx-react-lite";
import {PharmacyStock, SearchStore} from "./stores/search";
import {Stores} from "./stores/stores";
import {Product} from "./api/types";
import {AppMap} from "./components/map/map";
import {IntlProvider} from "react-intl";
import {RO_MESSAGES} from "./lang/ro";
import classNames from "classnames";
import {Results} from "./components/results/results";
import {action} from "mobx";
import {Spinner} from "react-bootstrap";
import {CheckCircleFill, XCircleFill} from "react-bootstrap-icons";
import {Searching} from "./components/searching/searching";
// import 'bootstrap/dist/css/bootstrap.min.css';

import {Search, ArrowUpShort, ArrowDownShort} from "react-bootstrap-icons";

import {Button} from 'react-bootstrap'

//@ts-ignore
window.s = AppState;

const App = observer(() => {

    const inputRef = useRef<HTMLInputElement>(null);
    const INTL_MESSAGES = RO_MESSAGES;

    console.log(AppState.searchOpened)

    return (
        <IntlProvider messages={INTL_MESSAGES} locale="ro" defaultLocale="ro">

            <div className={css.App}>

                <AppMap
                    className={css.map}
                    onClick={AppState.closeResults}
                />

                <div
                    className={classNames(css.searchContainer, AppState.searchOpened && css.opened)}
                    onClick={AppState.openResults}
                >
                    <div className={css.search}>
                        <input
                            disabled={AppState.loading}
                            enterKeyHint="search"
                            type="search"
                            placeholder={RO_MESSAGES["search.placeholder"]}
                            autoComplete="off"
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    AppState.search.search(e.currentTarget.value)
                                    e.currentTarget.blur()
                                }
                            }}
                            ref={inputRef}
                        />
                        <Button
                            variant="primary"
                            disabled={AppState.loading}
                            onClick={() => {
                                if (inputRef.current) {
                                    AppState.search.search(inputRef.current.value)
                                }
                            }}
                        >
                            {AppState.search.settled && <Search size={28}/>}
                            {!AppState.search.settled && <Spinner animation="border"/>}
                        </Button>

                        {!AppState.search.emptySearch && (
                            <Button
                                variant="primary"
                                className={css.resultsToggle}
                                disabled={AppState.loading}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    AppState.toggleResults()
                                }}

                            >
                                {!AppState.searchOpened && <ArrowUpShort size={36}/>}
                                {AppState.searchOpened && <ArrowDownShort size={36}/>}
                            </Button>
                        )}
                    </div>

                    <div className={css.content}>
                        {!AppState.search.settled &&
                            <Searching/>
                        }

                        {AppState.search.settled && (
                            <Results className={css.results}/>
                        )}
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
})

export default App;