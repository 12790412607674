import {Simulate} from "react-dom/test-utils";

export type Product = {
    pharmacy: string
    id: string
    name: string
    price: number | null

    image?: string | null
}

export type ProductStock = {
    // product id
    id: string

    // can order online
    online: boolean | null

    // stock locations
    locations: (StoreId & {
        maybe?: boolean
    })[]
}

export type StoreId = {
    // pharmacy chain name
    pharmacy: string,
    // store location id
    id: string
}

export type Store = StoreId & {
    name: string
    lat: number
    long: number
    address: string
    // hours are 1 per line, following M/Tu/W/Th/F/Sa/Su, line is empty if none
    hours: string | null
    phone: string | null
}

export type APISuccessResponse<T> = {
    success: true
    data: T
}

export type APIFailResponse = {
    success: false,
    error: {
        code: string,
        message: string,
        extra: unknown
    }
}

export type APIResponse<T> = APISuccessResponse<T> | APIFailResponse

export const API = process.env.REACT_APP_API

class APIError extends Error {
    name = 'APIError'

    code: string
    extra: unknown

    constructor(code: string, message: string, extra: unknown) {
        super(message);
        this.code = code
        this.extra = extra
    }
}

export async function processApiResp<T>(r: Response | Promise<Response>) {
    const json = await (await r).json() as APIResponse<T>;

    if (!json.success) {
        throw new APIError(json.error.code, json.error.message, json.error.extra)
    }

    return json.data;
}