import {DataFrame} from "../helpers/dataframe";
import {API, processApiResp, Store} from "./types";

export class PharmaciesFrame extends DataFrame<string[]> {
    protected fetch(): Promise<string[]> {
        return processApiResp(fetch(`${API}/pharmacies`))
    }

}

export const Pharmacies = new PharmaciesFrame({autoFetch: true});

// @ts-ignore
window.Pharmacies = Pharmacies